const newsInfo=[
    {
        id:1,
        text:"Полезные ссылки"
    },
    {
        id:2,
        text:"Инструкции АЦК"
    },
    {
        id:3,
        text:"Инструкции БАРС"
    }
]
export {newsInfo}